import { Component, OnInit , Inject } from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { RoomSetService } from '../service/room-set.service'
import { DialogService } from 'ng-devui/modal';
import { Router} from '@angular/router'
// import { io } from 'socket.io-client';
import * as io from 'socket.io-client';
import { TableWidthConfig } from 'ng-devui/data-table';
import { ModalService } from 'ng-devui/modal';
import { LiveUserModalComponent } from './liveUserModal.component';
import {LocalStorageService} from "angular-2-local-storage";


@Component({
  selector: 'app-liveUser',
  templateUrl: './liveUser.component.html',
  styleUrls: ['./liveUser.component.scss'],
})

export class LiveUserComponent implements OnInit {

  private socket: any;
  private dialog:any;
  public nickname:string;

  // logoSrc = 'https://res.hc-cdn.com/x-roma-components/1.0.10/assets/devui/logo.svg';
  logoSrc = 'https://ossdxj.oss-cn-shenzhen.aliyuncs.com/live/assets/img/icon_logo.png.png';

  joinRoomData = null//请求加入房间参数
  state_type = 0//1请求加入房间中

  tableData: Array<any> = [];

  dataTableOptions = {
    columns: [
      {
        field: 'lu_name',
        header: '用户名',
        fieldType: 'text'
      },
      {
        field: 'lu_room_name',
        header: '房间名',
        fieldType: 'text'
      },
      {
        field: 'lu_is_live',
        header: '状态',
        fieldType: 'text'
      },
      {
        field: 'lu_address',
        header: '位置',
        fieldType: 'text'
      },
      {
        field: 'lu_create_time',
        header: '创建时间',
        fieldType: 'text'
      },
      {
        field: 'id',
        header: '操作',
        fieldType: 'html',
      }
    ]
  };

  tableWidthConfig: TableWidthConfig[] = [
    // {
    //   field: '#',
    //   width: '50px'
    // },
    // {
    //   field: 'lu_name',
    //   width: '150px'
    // },
    // {
    //   field: 'lu_room_name',
    //   width: '150px'
    // },
    // {
    //   field: 'lu_is_live',
    //   width: '150px'
    // },
    // {
    //   field: 'lu_address',
    //   width: '200px'
    // },
    // {
    //   field: 'lu_create_time',
    //   width: '150px'
    // },
  ];

  constructor(
    public roomSetService: RoomSetService,
    public http: HttpClient,
    private dialogService: DialogService,
    public router:Router,
    public modalService:ModalService,
  private _localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    this.initSocket();

    this.nickname = this.roomSetService.user.nickname;

  }

  initSocket () {

    this.socket = io(this.roomSetService.socketUrl, {
      timeout: 300000,
      query:{
        token:this.roomSetService.token
      },
      transports : [ 'websocket' ]//必须制定传输协议
    })
    this.socket.on('connect', () => {
      console.log('建立链接')
    })
    this.socket.on('disconnect', () => {
      console.log('连接断开')
      this.tableData = [];
    })
    //通知有人加入（主播上线）
    this.socket.on('user_join',(data)=>{
      let temp = [];
      if(data){
        temp = JSON.parse(data);
      }
      this.tableData = temp;
    })
    //主播同意加入房间
    this.socket.on('agree_join_room',(data)=>{

      this.state_type == 0
      this.dialog.modalInstance.hide();
      if(data){
        this.openWindowWithRtc( JSON.parse(data));
      }
    })
    //主播拒绝加入房间
    this.socket.on('refuse_join_room',(data)=>{

      this.state_type == 0
      this.dialog.modalInstance.hide();
      if(data){
        const results = this.dialogService.open({
          id: 'dialog-service',
          width: '400px',
          maxHeight: '600px',
          showAnimate: false,
          title: '提示!',
          html: true,
          content: '主播拒绝您加入房间',
          backdropCloseable: false,
          dialogtype: 'warning',
          buttons: [
            {
              cssClass: 'primary',
              text: '确定',
              handler: ($event: Event) => {
                results.modalInstance.hide();
              },
            }
          ],
        });
      }
    })
  }

  //提醒开播
  showRemindLiveAction(model){

    let joinRoomDataTemp = {
      lu_room_id : model.lu_room_id,
      lu_id : this.roomSetService.user.id,
      user_id :this.roomSetService.user.id,
      lu_name : this.roomSetService.user.nickname,
    }
    //提醒开播
    this.socket.emit('remind_live',JSON.stringify(joinRoomDataTemp));
    //this.$Message.success('提醒发送成功');
    const results = this.dialogService.open({
      id: 'dialog-service',
      width: '346px',
      maxHeight: '600px',
      showAnimate: false,
      title: '提示',
      content: '提醒发送成功',
      backdropCloseable: true,
      dialogtype: 'success',
      buttons: [
        {
          cssClass: 'primary',
          text: '确定',
          handler: ($event: Event) => {
            results.modalInstance.hide();
          },
        }
      ],
    });
  }

  //发送申请加入房间请求
  showLiveUserAction(model){

    this.joinRoomData = {
      lu_room_id : model.lu_room_id,
      lu_id : this.roomSetService.user.id,
      user_id :this.roomSetService.user.id,
      lu_name : this.roomSetService.user.nickname,
    }
    //发送申请加入房间请求
    this.socket.emit('join_room',JSON.stringify(this.joinRoomData));
    this.state_type = 1;

    //显示提醒框
    this.dialog = this.dialogService.open({
      id: 'dialog-service',
      width: '346px',
      maxHeight: '600px',
      showAnimate: true,
      title: '提示',
      content: LiveUserModalComponent,
      backdropCloseable: false,
      showCloseBtn : false,
      dialogtype: 'standard',
      onClose: () => {
        console.log('on dialog closed');
      },
      buttons: [
        {
          id: 'btn-cancel',
          cssClass: 'common',
          text: '取消',
          handler: ($event: Event) => {
            this.cancelJoinRoom();
          },
        },
      ],
    });
  }


  openWindowWithRtc(item){//打开直播列表

    let userName = encodeURIComponent(this.roomSetService.user.nickname)
    let rtc_room_creater = encodeURIComponent(item.lu_name);
    let userId = this.roomSetService.user.id
    //let url = "http://localhost:4200/#/JoinRoom?roomId="+ item.rtc_room_id +"&userId="+ userId + "&userName="+ userName ;
    //let url = "https://live.epowerplus.cn/#/JoinRoom?roomId="+ item.lu_room_id +"&userId="+ userId + "&userName="+ userName + "&roomName=" + rtc_room_creater ;
    // let url = "http://localhost:4200/#/JoinRoom?roomId="+ item.rtc_room_id +"1&userId="+ userId + "&userName="+ userName + "&roomName=" + rtc_room_creater ;
    let host = window.location.host;
    let pro = window.location.protocol
    let url = pro + "//" + host + "/#/JoinRoom?roomId="+ item.lu_room_id +"&userId="+ userId + "&userName="+ userName + "&roomName=" + rtc_room_creater ;
    window.open(url);

  }

  //退出登录
  logoutAction(){

    const results = this.dialogService.open({
      id: 'dialog-service',
      width: '300px',
      maxHeight: '600px',
      showAnimate: false,
      title: '提示',
      content: '您确定要退出登录吗？',
      backdropCloseable: false,
      dialogtype: 'warning',
      buttons: [
        {
          cssClass: 'primary',
          text: '确定',
          handler: ($event: Event) => {
            results.modalInstance.hide();
            this._localStorageService.set("token" , null);
            this.roomSetService.user = null;
            this.roomSetService.token = null;
            // this.router.navigate(['login'],{})
            document.location.href = "/"
          }
        },
        {
          id: 'btn-cancel',
          cssClass: 'common',
          text: '取消',
          handler: ($event: Event) => {
            results.modalInstance.hide();
          }
        },
      ]
    });
  }

  cancelJoinRoom(){//取消加入房间
    if(this.state_type == 1){
      //取消加入
      this.dialog.modalInstance.hide();
      this.socket.emit('cancel_join_room',JSON.stringify(this.joinRoomData));
    }
    this.state_type == 0
  }

  ngOnDestroy(){
//取消连接
    if(this.socket){
      this.cancelJoinRoom();
      this.socket.disconnect();
    }
  }

}

