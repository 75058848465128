import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallRoomComponent } from './call-room/call-room.component';
import { JoinRoomComponent } from './join-room/join-room.component';
import { LoginComponent } from './login/login.component';
import { LiveUserComponent } from './LiveUser/liveUser.component';
import { AuthGuard } from './auth/AuthGuard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },//入口
  {
    path: 'login',
    component: LoginComponent ,
    canActivate : [ AuthGuard ]
  },//入口
  { path: 'JoinRoom', component: JoinRoomComponent },//直播页面
  {
    path: 'LiveUser/getPageList',
    component: LiveUserComponent,
    canActivate : [ AuthGuard ]
  },//直播列表
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
