import { Component, OnInit , Inject } from '@angular/core'
import {ActivatedRoute} from '@angular/router'
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { RoomSetService } from '../service/room-set.service'
import * as CryptoJS from 'crypto-js';
import { DialogService } from 'ng-devui/modal';
import { Router} from '@angular/router'
import { LocalStorageService } from 'angular-2-local-storage';
import * as  UUID  from 'uuid'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  account:string;
  password:string;
  joinRoomStatus : boolean;
  showLoading : boolean;
  version :string;

  constructor(
    private activateInfo: ActivatedRoute,
    public roomSetService: RoomSetService,
    public http: HttpClient,
    private dialogService: DialogService,
    public router:Router,
    private _localStorageService: LocalStorageService
  ) {
    this.joinRoomStatus = true
    // this.account = '13928720730'
    // this.password = '123456'
    this.version = this.roomSetService.webVersion;
  }

  ngOnInit(): void {
  }


  // 登录
  async loginAction(): Promise<void> {
    if(!this.account){
      return;
    }
    if(!this.password){
      return;
    }
    this.showLoading = true;

    const passwordTemp = CryptoJS.SHA256('666666' + this.password).toString();

    //时间戳
    const timestamp = new Date().valueOf() + "";
    //随机数
    const nonceStr =  UUID.v4().replace(/-/g, "");
    let  signature = "timestamp=" + timestamp + "&nonceStr=" + nonceStr + "&key=" + this.roomSetService.key
    // //签名
    signature =  CryptoJS.MD5(signature).toString().toUpperCase()

    const url = this.roomSetService.url + "/loginLive";
    const params = {
      account : this.account ,
      password : passwordTemp
    }
    const headers = new HttpHeaders({
      "Content-Type" : "application/json; charset=UTF-8",
      timestamp : timestamp,
      nonceStr : nonceStr,
      signature : signature
    })

    this.http.post(url, params , { headers : headers })
      .subscribe(
        (response : any)=>{

            if(response && response.code == 200){
              this.roomSetService.token = response.data;
              this._localStorageService.set("token", response.data);
              this.getUserInfo(this.roomSetService.token);
            }else{

              this.showLoading = false;
              this._localStorageService.set("token", null);
              const msg = response ? response.msg : '登录出错'
              const results = this.dialogService.open({
                id: 'dialog-service',
                width: '346px',
                maxHeight: '600px',
                title: '提示',
                content: msg,
                backdropCloseable: false,
                dialogtype: 'failed',
                buttons: [
                  {
                    cssClass: 'primary',
                    text: '确定',
                    handler: ($event: Event) => {
                      results.modalInstance.hide();
                    }
                  }
                ],
              });
            }
      },
      (error: Error) => {
          this.showLoading = false;
          console.log(error)
          if(!error['ok'] && error.name == "HttpErrorResponse"){
            this.showToast("error" , "无法连接到服务器");
          }
      },
      )
  }

  //获取用户信息
  async getUserInfo(token): Promise<void> {
    const url = this.roomSetService.url + "/SysUser/info";
    const params = {
    }

    //时间戳
    const timestamp = new Date().valueOf() + "";
    //随机数
    const nonceStr =  UUID.v4().replace(/-/g, "");
    let  signature = "timestamp=" + timestamp + "&nonceStr=" + nonceStr + "&key=" + this.roomSetService.key
    // //签名
    signature =  CryptoJS.MD5(signature).toString().toUpperCase()

    const headers = new HttpHeaders({
      "Content-Type" : "application/json; charset=UTF-8",
      token : token ,
      timestamp : timestamp,
      nonceStr : nonceStr,
      signature : signature
    })


    this.http.post(url, params , { headers : headers })
      .subscribe(
        (response : any)=>{
            this.showLoading = false;
            if(response){

              if(response.code == 200){
                //判断权限
                const index = response.data.permissions.indexOf("LiveUser/getPageList");
                if(index > -1){
                  this.roomSetService.token = token;
                  //设置用户
                  this.roomSetService.user = response.data;
                  this.router.navigate(['/LiveUser/getPageList'],{})
                }else{
                  this._localStorageService.set("token", null);
                  const results = this.dialogService.open({
                    id: 'dialog-service',
                    width: '346px',
                    maxHeight: '600px',
                    title: '提示',
                    content: '权限不足，请联系管理员',
                    backdropCloseable: false,
                    dialogtype: 'failed',
                    buttons: [
                      {
                        cssClass: 'primary',
                        text: 'Ok',
                        handler: ($event: Event) => {
                          results.modalInstance.hide();
                        }
                      }
                    ],
                  });
                }
              }
            }else{
              this._localStorageService.set("token", null);
              const results = this.dialogService.open({
                id: 'dialog-service',
                width: '346px',
                maxHeight: '600px',
                title: '提示',
                content: '数据获取异常',
                backdropCloseable: false,
                dialogtype: 'failed',
                buttons: [
                  {
                    cssClass: 'primary',
                    text: 'Ok',
                    handler: ($event: Event) => {
                      results.modalInstance.hide();
                    }
                  }
                ],
              });
            }
      },
        (error: Error) => {
          console.log(error)
        },
      )
  }

  msgs: Array<Object> = [];
  showToast(type: any , msg: string) {
    this.msgs = [{ severity: type, detail: msg }];
  }

}

