/**
 * 路由守卫
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { RoomSetService } from '../service/room-set.service'
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as  UUID  from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    public _localStorageService : LocalStorageService,
    public roomSetService : RoomSetService,
    public http: HttpClient,
  ) {
  }

  canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):Observable<boolean>|boolean {

    // console.log(window.location.protocol)
    // console.log(document.location.host)
    // console.log(document.location.href)
    // console.log(document.location.pathname)

    let _this = this;
    if(this.roomSetService.user){
      return true;
    } else {
      return new Observable<boolean>((observer) => {
        this.checkLogin( function(flag : boolean ){

          let isLoginPage = false;
          const temp = document.location.href.split(document.location.host);
          //console.log(temp)
          if( temp[1] === '/' || temp[1] === '/#/' || temp[1].indexOf("login") > -1){
            isLoginPage = true;
            //console.log("是登录页面")
          }
          if(flag){
            //如果是登录页面，且已经登录
            if(isLoginPage){
              _this.router.navigate(['/LiveUser/getPageList'],{})
            }else{
              observer.next(true);
            }
          }else{
            if(isLoginPage){
              observer.next(true);
            }else{
              _this.router.navigate(['/login'],{})
            }
          }
          observer.complete();
        });
      });
    }
  }

  checkLogin(cb) {
    if(this.roomSetService.user){
      cb(true)
    }else{
      //获取用户信息
      const token = this._localStorageService.get("token");
      if(token){
        this.getUserInfo(token , function(flag :boolean){
          cb(flag);
        })
      }else{
        cb(false);
      }

    }
  }

  //获取用户信息
  getUserInfo(token , cb) {

    const url = this.roomSetService.url + "/SysUser/info";
    const params = {
    }
    //时间戳
    const timestamp = new Date().valueOf() + "";
    //随机数
    const nonceStr =  UUID.v4().replace(/-/g, "");
    let  signature = "timestamp=" + timestamp + "&nonceStr=" + nonceStr + "&key=" + this.roomSetService.key
    // //签名
    signature =  CryptoJS.MD5(signature).toString().toUpperCase()

    const headers = new HttpHeaders({
      "Content-Type" : "application/json; charset=UTF-8",
      token : token ,
      timestamp : timestamp,
      nonceStr : nonceStr,
      signature : signature
    })

    this.http.post<any>(url, params , { headers : headers })
      .subscribe(
        (response : any)=>{
          if(response && response.code == 200){
            //设置用户
            this.roomSetService.user = response.data;
            this.roomSetService.token = token;
            //console.log(response)
            cb(true);
          }else{
            this._localStorageService.set("token", null);
            cb(false);
          }
        },
        (error: Error) => {
          cb(false);
          if(error.name == "HttpErrorResponse"){
            //this.showToast("error" , "无法连接到服务器");
          }
        },
      )
  }
}
