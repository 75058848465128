import { Component, Input } from '@angular/core';
@Component({
  selector: 'd-modal-liveUserModal',
  templateUrl: './liveUserModal.component.html',
  styleUrls: ['./liveUserModal.component.css']
})
export class LiveUserModalComponent {
  @Input() data: any;
  @Input() handler: Function;
  buttonsObj: any = 'id?: string; cssClass?: string; text: string; handler: ($event: Event) => void;';

  constructor() {
  }

  close($event) {
    this.handler($event);
  }

  onClick(event) {
  }
}
