<d-layout>
  <d-header>
    <div class="logo">
      <span class="logo-devui">
        <img [src]="logoSrc" width="150px" height="64px" />
      </span>
      <span class="text">电享加远程协助</span>
    </div>
    <div class="top_logout">
      <span (click)="logoutAction()" style="cursor:pointer;">退出（{{nickname}}）</span>
    </div>
  </d-header>
  <d-content>
    <d-breadcrumb>
      <d-breadcrumb-item>
        <span>在线用户</span>
      </d-breadcrumb-item>
    </d-breadcrumb>
    <div class="inner-content">

      <d-data-table [dataSource]="tableData" [scrollable]="true" [type]="'striped'" [tableWidthConfig]="tableWidthConfig">
        <thead dTableHead>
        <tr dTableRow>
          <th dHeadCell>#</th>
          <th dHeadCell *ngFor="let colOption of dataTableOptions.columns">{{ colOption.header }}</th>
        </tr>
        </thead>
        <tbody dTableBody>
          <ng-template let-rowItem="rowItem" let-rowIndex="rowIndex">
            <tr dTableRow>
              <td dTableCell>{{ rowIndex + 1 }}</td>
              <td dTableCell>
              {{  rowItem['lu_name']  }}
              </td>
              <td dTableCell>
                {{  rowItem['lu_room_name']  }}
              </td>
              <td dTableCell>
                <section>
                  <d-status *ngIf="rowItem['lu_is_live'] == 0"  type="waiting">未开播</d-status>
                  <d-status *ngIf="rowItem['lu_is_live'] == 1"  type="success">直播中</d-status>
                  <d-status *ngIf="rowItem['lu_is_live'] == 2"  type="error">录像中</d-status>
                </section>
              </td>
              <td dTableCell>
                {{  rowItem['lu_address']  }}
              </td>
              <td dTableCell>
                {{  rowItem['lu_create_time']  }}
              </td>
              <td dTableCell >
                <section>
                  <d-button *ngIf="rowItem['lu_is_live'] == 2" bsStyle="primary" (click)="showRemindLiveAction(rowItem)">提醒开播</d-button>
                  <d-button *ngIf="rowItem['lu_is_live'] == 0"  bsStyle="primary" style="margin-right: 4px;"  (click)="showLiveUserAction(rowItem)" >请求开播并加入房间</d-button>
                  <d-button *ngIf="rowItem['lu_is_live'] == 1"  bsStyle="primary" style="margin-right: 4px;"  (click)="showLiveUserAction(rowItem)" >请求加入房间</d-button>
                </section>
               </td>
            </tr>
          </ng-template>
        </tbody>
        <ng-template #noResultTemplateRef>
          <div style="text-align: center; padding: 10px;background-color: white;">
            暂无数据
          </div>
        </ng-template>
      </d-data-table>
    </div>
  </d-content>
</d-layout>
