
<div *ngIf="joinRoomStatus" class="container-root">
  <d-toast [life]="3000" [value]="msgs"></d-toast>
  <div class="container-box">
    <form dForm ngForm [layout]="'vertical'" [dValidateRules]="{ message: '表单校验不通过，请检查' }" >
      <div style="text-align: center; margin: 30px 0 0 0;" ><img src="https://ossdxj.oss-cn-shenzhen.aliyuncs.com/live/assets/img/icon_logo.png.png" style="width: 300px;height: 138px;"><img></div>
      <div [ngModelGroup]="'textGroup'">
        <d-form-item>
          <d-form-label style="font-size: 14px">手机号 {{ count$ | async }}</d-form-label>
          <d-form-control>
            <input autocomplete="off"  style="width: 100%;height: 28px;" dTextInput name="roomId" placeholder="手机号码" [(ngModel)]="account"
                   [dValidateRules]="[
                                        { required: true ,message: '请输入手机号码' },
                                        { minlength: 11 ,message: '请输入正确的手机号码' },
                                        { maxlength: 11 ,message: '请输入正确的手机号码' }
                                      ]"
            />
          </d-form-control>
        </d-form-item>
        <d-form-item>
          <d-form-label style="font-size: 14px">密&nbsp;&nbsp;码</d-form-label>
          <d-form-control>
            <input id="userName" style="width: 100%;height: 28px;" dTextInput autocomplete="off" type="password" name="userName" placeholder="请输入密码" [(ngModel)]="password"
                   [dValidateRules]="[
                                        { required: true, message: '请输入密码' },
                                        { minlength: 6, message: '密码长度不少于6位' }
                                     ]"
                   (keyup)="($event.which === 13) ? loginAction() : 0"
            />
          </d-form-control>
        </d-form-item>
      </div>
      <d-form-item>
        <div class="btn-group"  style="text-align: center; margin: 30px 0 20px 0;">
          <d-button [showLoading]="showLoading"  circled="true" icon="icon-add-member" width="130px" style="margin-bottom: 10px;"  bsStyle="primary" dFormSubmit  (btnClick)="loginAction()">登录</d-button>
          <br/>
          <span style="font-size: 14px">版本：{{version}}</span>
        </div>
      </d-form-item>
    </form>
  </div>
</div>
